import Vue from 'vue'
import VueRouter from 'vue-router'
import MultiGuard from 'vue-router-multiguard';
import LandingPage from '../views/LandingPage.vue'
import AuthMiddleware from "@/permission/AuthMiddleware.ts";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing-page',
    meta: {
      noAuth: true
    },
    component: LandingPage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "app1" */ '../views/About.vue')
  },
  {
    path: '/favorite-animal',
    name: 'favorite-animal',
    meta: {
      noAuth: true
    },
    component: () => import(/* webpackChunkName: "app2" */ '../views/FavoriteAnimal.vue')
  },
  {
    path: '/songs',
    name: 'song',
    meta: {
      noAuth: true
    },
    component: () => import(/* webpackChunkName: "app3" */ '../views/Song.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      noAuth: true
    },
    component: () => import(/* webpackChunkName: "app4" */ '../views/Login.vue')
  },
  {
    path: '/create-user',
    name: 'create-user',
    meta: {
      noAuth: true
    },
    component: () => import(/* webpackChunkName: "app5" */ '../views/CreateUser.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      noAuth: true
    },
    component: () => import(/* webpackChunkName: "app6" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "app7" */ '../views/Home.vue')
  },
  {
    path: '/edit-links',
    name: 'edit-links',
    component: () => import(/* webpackChunkName: "app8" */ '../views/EditLinks.vue')
  },
  {
    path: '/edit-fields',
    name: 'edit-fields',
    component: () => import(/* webpackChunkName: "app9" */ '../views/EditFields.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(MultiGuard([
  AuthMiddleware,
]));

Vue.$router = router;
export default router
