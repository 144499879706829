import Model from "@/models/base";


export default class CollectData extends Model {
  first_name = "";
  last_name = "";
  gender = "";
  birthday = "";
  country = "";
  email = "";
  phone = "";
  favorite_animal = "";

  constructor() {
    super()
  }

  get COLLECTION() {
    return "CollectData"
  }
}
