import {auth} from "../firebase";
import store from '../store'

async function AuthMiddleware(to, from, next) {
    if (to.matched.some(route=>route.meta.noAuth)) {
        return next();
    }

    try {
        if (!auth.currentUser || !store.getters.currentUser) {
            await store.dispatch("setUser");
        }
    } catch (e) {
        return next('/login');
    }

    return next();
}

export default AuthMiddleware;
