<template>
  <video-background
      :src="require('../assets/animation/animation_one.mp4')"
      :loop="false"
      :muted="true"
      ref="videobackground"
      @load="handleLoad"
      @playing="handlePlaying"
      style="height: 100vh;">
    <div class="container h-100">
      <div class="collection-screen h-100">
        <h1 class="mt-2">Welcome to the Jungle</h1>
        <button style="display: none" ref="play" type="button" @click="onplay">Play</button>
        <carousel :per-page="1" ref="carousel" pagination-active-color="#666"
                  :touch-drag="false" :mouse-drag="false"
        @pagination-click="validPage">
          <slide v-if="enable_screen1" title="name">
            <div class="slide1 row">
              <div class="col-md-6 col-7">
                <div>
                  <input type="text" placeholder="First Name" autofocus class="form-control form-input"
                         v-model="collect_data.first_name"/>
                  <input type="text" placeholder="Last Name" class="form-control form-input mt-2"
                         v-model="collect_data.last_name"/>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" autofocus name="inlineRadioOptions"
                           id="inlineRadio1" value="male" v-model="collect_data.gender">
                    <label class="form-check-label" for="inlineRadio1">Male</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                           id="inlineRadio2" value="female" v-model="collect_data.gender">
                    <label class="form-check-label" for="inlineRadio2">Female</label>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                           id="inlineRadio3" value="other" v-model="collect_data.gender">
                    <label class="form-check-label" for="inlineRadio3">Other</label>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <slide v-if="enable_screen2" title="email_phone">
            <div class="slide2 row">
              <div class="col-md-6 col-7">
                <input v-if="fields.show_email" type="email" placeholder="Email" class="form-control form-input" v-model="collect_data.email"/>
<!--                <input  v-if="fields.show_phone" type="text" placeholder="Phone #" class="form-control form-input mt-2" v-model="collect_data.phone"/>-->
                <vue-phone-number-input v-model="collect_data.phone_number" color="#fff"
                                        default-country-code="US" :no-example="true"
                                        :no-country-selector="true"
                @update="onUpdate"></vue-phone-number-input>
              </div>
            </div>
          </slide>
          <slide v-if="enable_screen3" title="birthday_city">
            <div class="slide3" style="height: 350px;">
              <div>
                <div class="row">
                  <div class="col-md-6 col-7">
                    <datepicker v-if="fields.show_birthday" v-model="collect_data.birthday" input-class="form-control form-input"
                                placeholder="Birthday" format="MM/dd/yyyy"></datepicker>
                    <input v-if="fields.show_country" type="text" placeholder="City, Country" class="form-control form-input mt-2" @focus="handleFocus()"
                           autocomplete="nope" v-model="collect_data.country" readonly="readonly"/>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
        <search-country :show="show" :toggle-search="toggleSearch" v-model="collect_data.country"></search-country>
        <a class="btn btn-secondary btn-next btn-style1" href="javascript:void(0)" @click="handleClickNext()">Next</a>

        <error-modal v-model="error_screen1" msg="Please enter all information before proceeding"></error-modal>
        <error-modal v-model="error_screen2" :msg="msg"></error-modal>
      </div>
    </div>
  </video-background>
</template>

<script>
import SearchCountry from "@/components/SearchCountry";
import {mapState} from "vuex";
import ErrorModal from "@/components/ErrorModal";
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'LandingPage',
  components: {
    ErrorModal,
    SearchCountry,
    Datepicker
  },
  data() {
    return {
      show: false,
      currentPage: 0,
      error_screen1: false,
      error_screen2: false,
      msg: "",
      isValidPhone: true,
      played: false
    }
  },
  computed: {
    ...mapState([
        "fields", "collect_data", 'countries'
    ]),
    enable_screen1() {
      return this.fields.show_name
    },
    enable_screen2() {
      return this.fields.show_phone || this.fields.show_email;
    },
    enable_screen3() {
      return this.fields.show_birthday || this.fields.show_country;
    },
    enable_screen4() {
      return this.fields.show_question;
    },
  },
  created() {
    if (localStorage.getItem("collectedData")) {
      this.$router.replace("/songs")
    }
    this.$store.dispatch("getCountries");
  },
  methods: {
    onplay() {
      this.$refs.videobackground.player.play()
    },
    handleLoad() {
      this.$refs.play.touchstart();
    },
    handlePlaying() {
      let that = this;
      setTimeout(() => {
        const videoElement = document.querySelector('video');
        if (!videoElement.playing) {
          that.$refs.videobackground.poster = require('../assets/imgs/bg1.png');
          that.$refs.videobackground.player.hide()
        }
      }, 2000)
    },
    onUpdate(payload) {
      this.isValidPhone = payload.isValid;
      this.collect_data.phone = payload.formatInternational || '';
    },
    handleClickNext() {
      let carousel = this.$refs.carousel, that = this;
      const currentPage = carousel.currentPage;
      if (carousel.getSlide(currentPage).title == "name" && !this.validScreen1()) {
        return;
      } else if (carousel.getSlide(currentPage).title == "email_phone" && !this.validScreen2()) {
        return;
      }
      const pageCount = carousel.pageCount;
      this.show = false;
      if (currentPage < pageCount - 1) {
        this.currentPage = currentPage + 1;
        carousel.goToPage(this.currentPage);
      } else {
        if (this.enable_screen4) {
          this.$router.push("/favorite-animal")
        } else {
          this.$store.dispatch('addMember', this.collect_data);
          this.collect_data.save().then(() => {
            localStorage.setItem("collectedData", "1");
            that.$router.replace("/songs")
          })
        }
      }
    },
    handleFocus() {
      this.show = Math.random().toString(36).substring(7);
    },
    toggleSearch(val) {
      this.show = val;
    },
    handlePageChange() {
      this.show = false;
    },
    validPage(nextPage) {
      let carousel = this.$refs.carousel;
      let currentPage = this.currentPage;
      if (currentPage >= 0) {
        if (nextPage > currentPage) {
          if (carousel.getSlide(currentPage).title == "name" && !this.validScreen1()) {
            carousel.goToPage(currentPage);
            return;
          } else if (carousel.getSlide(currentPage).title == "email_phone" && !this.validScreen2()) {
            carousel.goToPage(currentPage);
            return;
          }
        }
      }
      this.currentPage = nextPage;
    },
    validScreen1() {
      let is_valid = true;
      this.error_screen1 = false;
      if (this.collect_data.first_name.trim() == "" || this.collect_data.last_name.trim() == "" ||
        this.collect_data.gender == "") {
        is_valid = false;
      }
      if (!is_valid) {
        this.error_screen1 = true;
      }
      return is_valid
    },
    validScreen2() {
      let is_valid = true;
      this.error_screen2 = false;
      this.msg = "";
      if (this.collect_data.email.trim() == "" || this.collect_data.phone.trim() == "") {
        this.msg = "Please enter either email and/or phone number before proceeding"
        is_valid = false;
      } else if (!this.validEmail(this.collect_data.email)) {
        this.msg = "Valid email required.";
        is_valid = false;
      } else if (!this.isValidPhone) {
        this.msg = "Valid phone required.";
        is_valid = false;
      }
      if (!is_valid) {
        this.error_screen2 = true;
      }
      return is_valid
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>

<style lang="scss">
.collection-screen {
  position: relative;
  h1 {
    font-size: 16px;
    text-align: center;
    font-family: "NeonClubMusic";
    color: #fff;
  }
  .VueCarousel-pagination {
    position: fixed;
    bottom: 10px;
    width: auto !important;
  }
  .slide1, .slide2, .slide3 {
    margin-top: 35px;
  }
  .form-check-input:checked:before  {
    background-color: #666;
  }
  .form-check-label {
    color: white;
  }
}
#MazPhoneNumberInput .input-phone-number .input-tel__input {
  background-color: transparent;
  color: #fff;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #fff;
  font-size: 18px;
  border-radius: 0 !important;
  border-width: 2px;
  border-color: #fff;
  font-family: Giorgio, Arial !important;
  box-shadow: none !important;
}
#MazPhoneNumberInput .input-phone-number .input-tel__input::placeholder {
  color: #fff;
  text-align: center;
}
</style>
