import Model from "@/models/base";


export default class OzzieLovin extends Model {
  song_title = "";
  spotify_url = "";
  apple_url = "";
  youtube_url = "";
  amazon_url = "";
  show_name = true;
  show_birthday = true;
  show_country = true;
  show_email = true;
  show_phone = true;
  show_question = true;

  constructor() {
    super()
  }

  get COLLECTION () {
    return "OzzieLovin"
  }

  static get instance () {
    return new OzzieLovin();
  }

  get document() {
    return this.collection().doc('DataFields')
  }

  get countries() {
    return this.collection().doc('Countries')
  }

  get animals() {
    return this.collection().doc('Animals')
  }

  async getDataFields() {
    let result = null;
    let doc = await this.document.get();
    if (doc.exists) {
      result = OzzieLovin.from(doc.data(), doc.id);
    }
    return result;
  }
}
