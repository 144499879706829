import firebase from "firebase";
import {StoreDB} from "@/firebase";


export default class Model {
  id = "";
  createdAt = firebase.firestore.Timestamp.now().toDate().toISOString();

  get COLLECTION () {
    throw new TypeError('Override this');
  }

  get firestore () {
    return StoreDB;
  }

  static get instance () {
    throw new TypeError('Override this');
  }

  async getById (id) {
    const modelCollection = this.firestore.collection(this.COLLECTION);
    const record = await modelCollection.doc(id).get();
    if (!record.exists) {
      throw new Error(`${this.COLLECTION} ${id} not found`);
    }

    Object.assign(this, record.data());
    this.id = record.id;
    return this;
  }

  async insert() {
    const modelCollection = this.firestore.collection(this.COLLECTION);

    // const {TIMESTAMP} = firebase.database.ServerValue;
    // const now:firebase.firestore.FieldValue=firebase.firestore.FieldValue.serverTimestamp(); // deprecated
    // https://firebase.google.com/docs/reference/js/FireBaseManager.firestore_space.Timestamp
    const now = firebase.firestore.Timestamp.now().toDate().toISOString();

    // const {TIMESTAMP} = firebase.database.ServerValue;
    if (!this.createdAt) {
      this.createdAt = now;
    }
    const result = { ...this };
    delete result.id;
    const model = await modelCollection.add(JSON.parse(JSON.stringify(result)));
    this.id = model.id;
    return this;
  }
  save() {
    if (this.id) {
      return this.update();
    } else {
      return this.insert();
    }
  }

  async update (commit = true) {
    const modelCollection = this.firestore.collection(this.COLLECTION);

    if (commit) {
      const result = { ...this };
      const id = result.id;
      delete result.id;
      await modelCollection.doc(id).set(JSON.parse(JSON.stringify(result), { merge: true }));
    }
    return this;
  }

  async delete () {
    const modelCollection = this.firestore.collection(this.COLLECTION);
    const record = await modelCollection.doc(this.id);
    return record.delete();
  }

  collection() {
    return this.firestore.collection(this.COLLECTION);
  }

  async updateProps(props) {
    await this.firestore.collection(this.COLLECTION).doc(this.id).update(props);
  }

  cloneNewObject () {
    let newObject = {}
    const result = { ...this };
    newObject = JSON.parse(JSON.stringify(result))
    return newObject
  }

  static from (data = {}, id) {
    const newObj = this.instance;
    const obj = typeof data === 'object' ? data : {};
    if (!id && !data.id) {
      throw new Error("id not found");
    }
    Object.assign(newObj, { id }, obj, { id });
    return newObj;
  }
}
