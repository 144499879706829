import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import {initFirebaseManager} from "@/firebase/constructor";

const config = {
  apiKey: "AIzaSyD7YeurC3mT7gb1JRvQ-D4_8oeG8wTaNfg",
  authDomain: "ozzie-lovin.firebaseapp.com",
  projectId: "ozzie-lovin",
  storageBucket: "ozzie-lovin.appspot.com",
  messagingSenderId: "87949295331",
  appId: "1:87949295331:web:ff4d4ca386b8578dc68fd6",
  measurementId: "G-YJ1XTL9MW9"
};

firebase.initializeApp(config)

export const auth = firebase.auth()
export const DB = firebase.database()
export const StoreDB = firebase.firestore()
export const fstorage = firebase.storage()

initFirebaseManager(firebase.firestore, StoreDB)
export default firebase;
