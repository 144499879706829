<template>
  <div :class="{'s-modal': value}">
    <div class="error-content" v-if="value">
      <p>{{ msg }}</p>
      <div class="d-flex justify-content-center">
        <a class="btn btn-secondary btn-style2" @click="hideModal()">OK</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    value: {
      default: false,
      type: Boolean
    },
    msg: {
      type: String,
      default: ''
    }
  },
  methods: {
    hideModal() {
      this.$emit('input', false);
    }
  }
}
</script>

<style scoped lang="scss">
.s-modal {
  position: absolute;
  top: 34%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  .error-content {
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #ED1C24;
    padding: 15px;

    p {
      text-align: center;
      color: #fff;
    }
  }
}
</style>
