
export default class FireBaseManager {
  static db;
  static firestoreSpace;
  constructor(firestoreSpace, db) {
    FireBaseManager.firestoreSpace = firestoreSpace
    if (db) {
      FireBaseManager.db = db
    }
  }
}

// this method should be called very first on your application bootstrap process
export const initFirebaseManager = (firestoreSpace, db) => {
  return new FireBaseManager(firestoreSpace, db)
}
