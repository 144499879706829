import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app';
import FireBaseManager from "@/firebase/constructor";
import User from "@/models/User";
import OzzieLovin from "@/models/Ozzielovin";
import CollectData from "@/models/CollectData";
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    fields: {},
    countries: [],
    animals: [],
    collect_data: new CollectData()
  },
  getters: {
    currentUser: state => {
      return state.user;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setFields(state, fields) {
      state.fields = fields;
    },
    setCountries(state, data) {
      state.countries = data;
    },
    setAnimal(state, data) {
      state.animals = data;
    }
  },
  actions: {
    setUser(context) {
      return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(async fuser => {
          if (fuser) {
            let user = await new User().getById(fuser.uid);
            // set current user for firebase manager
            FireBaseManager.currentUser = user;
            context.commit("setUser", user);
          } else {
            /**
             * Reload page for clear vuex store data
             */
            context.commit("setUser", null);
            if (Vue.$router.currentRoute.path !== '/login')
              window.location.href = '/login';
          }
          resolve(fuser);
        });
      })
    },
    async createUser(context, fuser) {
      let user = new User();
      user.id = fuser.uid;
      user.email = fuser.email;
      await user.save()
    },
    getDataFields(context) {
      let instance = new OzzieLovin();
      instance.document.onSnapshot((doc) => {
        if (doc.exists) {
          context.commit('setFields', OzzieLovin.from(doc.data(), doc.id))
        } else {
          let obj = new OzzieLovin();
          obj.id = "DataFields";
          obj.save().then((data) => {
            context.commit("setFields", data);
          })
        }
      })
    },
    getCountries(context) {
      let instance = new OzzieLovin();
      instance.countries.onSnapshot((doc) => {
        if (doc.exists) {
          context.commit('setCountries', doc.data().data);
        }
      })
    },
    getAnimals(context) {
      let instance = new OzzieLovin();
      instance.animals.onSnapshot((doc) => {
        if (doc.exists) {
          context.commit('setAnimal', doc.data().data);
        }
      })
    },
    addMember(context, obj) {
      if (obj.email) {
        let profile = {
          email: obj.email,
          fist_name: obj.first_name,
          last_name: obj.last_name,
          region: obj.country,
          country: "United States",
          favorite_animal: obj.favorite_animal,
          birthday: obj.birthday ? obj.birthday.toLocaleDateString("en-US"): null ,
          gender: obj.gender
        }
        if (obj.phone) {
          profile.phone_number = obj.phone;
        }
        let url = `https://us-central1-ozzie-lovin.cloudfunctions.net/addMember`;
        axios.post(url, profile).then(res => {
          console.log(res);
        }).catch(err => {
          console.log(err);
        })
      }
    }
  },
  modules: {
  }
})
