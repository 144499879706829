<template>
  <div :class="{'s-modal': show}">
    <div class="search-country" v-if="show">
      <input type="text" class="form-control form-input" placeholder="Search..." ref="input" v-model="search">
      <ul class="results">
        <li v-for="c in searched_countries" :key="c"  @click="country = c" :class="{active: country == c}">
          {{ c }}
        </li>
      </ul>

      <div class="d-flex justify-content-center mt-2">
        <a class="btn btn-secondary btn-style1" @click="hideSearch()">OK</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SearchCountry",
  props: {
    value: {
      type: String,
      default: ""
    },
    show: {
      type: [Boolean , String],
      default: false
    },
    toggleSearch: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      search: "",
      country: ""
    }
  },
  watch: {
    show: {
      handler(val) {
        let that = this;
        if (val) {
          setTimeout(() => {
            that.$refs.input.focus();
          }, 100);

        }
      },
      immediate: true
    },
    value: {
      handler(val) {
        this.country = val;
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
      'countries'
    ]),
    searched_countries() {
      if (this.search) {
        return this.countries.filter((country) => {
          return country.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        })
      }
      return this.countries;
    }
  },
  methods: {
    hideSearch() {
      this.$emit("input", this.country);
      this.toggleSearch(false)
    },

  }
}
</script>

<style scoped lang="scss">
.s-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  .search-country {
    background-color: #F2F2F2;
    width: 90%;
    border-radius: 15px;
    padding: 10px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 30%;
    .results {
      list-style: none;
      padding-left: 0;
      margin-top: 10px;
      height: 200px;
      overflow: auto;

      li {
        margin-bottom: 5px;
        padding-left: 10px;
        cursor: pointer;
      }
      li:hover, li.active {
        background-color: #c1c1c1;
        border-radius: 10px;
      }
    }
    .form-input {
      color: #4D4D4D;
      border-color: #333333 !important;
      font-size: 16px !important;
    }
    .form-input::placeholder {
      color: #4D4D4D !important;
    }
  }
}

</style>
