import Vue from 'vue'
import App from './App.vue'

import "./assets/main.scss"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

import VueRouter from 'vue-router';
import router from './router'
import store from './store'
import VideoBackground, { Plugin } from 'vue-responsive-video-background-player'
import VueCarousel from 'vue-carousel';
import { ModalPlugin } from 'bootstrap-vue'
import VueSlimScroll from 'vue-slimscroll';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.use(VueSlimScroll)
Vue.use(ModalPlugin)
Vue.use(VueCarousel);
Vue.component('video-background', VideoBackground);
Vue.use(Plugin);
Vue.use(VueRouter)
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
