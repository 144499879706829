<template>
  <div id="__layout">
    <div id="application-root">
      <router-view/>

    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "App",
  created() {
    this.$store.dispatch('getDataFields');
  },
  mounted() {
    $(document).ready(function() {
      Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get: function () {
          return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        }
      });
    });
  }
}
</script>

<style>
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}
#application-root {
  height: 100% !important;
  width: 100% !important;
  min-height: 568px;
  box-shadow: rgba(25, 29, 33, 0.5) 0px 25px 60px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  flex: 0 1 100%;
  position: relative;
}
#__layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: radial-gradient(56% 100%, rgb(255, 255, 255) 25%, rgb(213, 224, 232) 150%);
}
body {
  margin: 0;
}

@media (min-height: 57rem) and (min-width: 34rem) {
  #application-root {
    border-radius: 20px;
    overflow: hidden;
  }
}

@media (min-height: 57rem) {
  #application-root {
    max-height: 42rem;
  }
  #__layout {
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
  }
}

@media (min-width: 34rem) {
  #application-root {
    max-width: 24rem;
    flex: 0 1 24rem;

  }
  #__layout {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
  }
}

@media (min-width: 32rem) {
  #application-root {
    max-width: 24rem;
    flex: 0 1 24rem;
  }
}
</style>
