import Model from "@/models/base";


export default class User extends Model {
  email = "";
  role = "";

  constructor() {
    super()
  }

  get COLLECTION () {
    return "User"
  }

  static get instance () {
    return new User();
  }

  async getUserByEmail(email) {
    let result = null;

    const records = await this.collection()
      .where("email", "==", email)
      .limit(1)
      .get();

    records.forEach(docSnapshot => {
      let record = User.from(docSnapshot.data(), docSnapshot.id);
      result = record;
    });
    return result;
  }
}
